import { Col, Row, } from 'reactstrap';
import CampsiteCard from "./CampsiteCard";
import { selectAllCampsites } from './campsitesSlice';

const CampsitesList = () => {
    const campsites = selectAllCampsites();

    return (     
        <Row className="ms-auto">
            {campsites.map((campsite) => {
                return (
                    //<Col md="5" className="m-4" key={campsite.id} original, below we are putting it on it's own line for readability>
                    <Col 
                    md="5" 
                    className="m-4" 
                    key={campsite.id} 
                    >
                        <CampsiteCard campsite={campsite} />
                    </Col>
                );
            })}
        </Row>  
    );
};

export default CampsitesList;
